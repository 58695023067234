<template>
  <div class="update-entity">
    <div class="edit-page__header" v-if="isReady && !isOneStepEdit">
      <div class="wrapper_edit_forms content d-flex justify-content-between">
        <div class="zq-create-page-title-wrapper">
          <h3 class="zq-child-title-text zq-edit-title">{{ texts.editPage.pageTitle }}</h3>
        </div>
        <ActionsForEdit
          @isShowDropdown="isShowDropdown"
          :isShowUpdate="false"
        />
      </div>
      <div class="tabs_wrapper">
        <div
          @click="showTab('settings')"
          :class="[{active: tabName === 'settings', notSaved:  !isSettingsDataSaved}, tabClasses]"
        >
          Settings
          <div class="edit-tooltip-wrapper" v-if="!isSettingsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div
              class="tooltip-old bs-tooltip-old-auto fade show"
              role="tooltip"
              aria-hidden="true"
              x-placement="right"
            >
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          @click="showTab('celebration-messages')"
          :class="[{active: tabName === 'celebration-messages', notSaved:  !isCelebrationMessagesDataSaved}, tabClasses]"
        >
          Celebration Messages
          <div class="edit-tooltip-wrapper" v-if="!isCelebrationMessagesDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div
              class="tooltip-old bs-tooltip-old-auto fade show"
              role="tooltip"
              aria-hidden="true"
              x-placement="right"
            >
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="tilesData"
          @click="showTab('tiles')"
          :class="[{active: tabName === 'tiles', notSaved:  !isTilesDataSaved}, tabClasses]"
        >
          Tiles
          <div class="edit-tooltip-wrapper" v-if="!isTilesDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
        <div
          v-if="translatableFields.length"
          @click="showTab('translations')"
          :class="[{active: tabName === 'translations', notSaved:  !isTranslationsDataSaved}, tabClasses]"
        >
          Translations
          <div class="edit-tooltip-wrapper" v-if="!isTranslationsDataSaved">
            <i aria-hidden="true" class="mt-2 fa fa-question-circle-o"></i>
            <div class="tooltip-old bs-tooltip-old-auto fade show">
              <div class="arrow" style="top: 18px;"></div>
              <div class="tooltip-old-inner">Data not saved!</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-content content position-relative" v-if="isReady && !isOneStepEdit">
      <div v-if="tabName === 'settings' && isReady">
        <EditInstantWinSettings
          v-if="formList.length"
          :list="formList"
          :descriptions="descriptions"
          :texts="texts"
          :model="model"
          :tilesData="tilesData"
        />
      </div>
      <EditCelebrationMessages
        v-if="tabName === 'celebration-messages'"
        :texts="texts"
        :descriptions="descriptions"
        :isMessagesSettingsEdit="true"
      />
      <PreviewSpiner v-if="!isReady" />
      <EditInstantWinTiles
        v-if="tilesData.length && tabName === 'tiles'"
        :key="instantWinTilesKey"
        :instantWinType="instantWinType"
        :entityData="entityData"
        @settingInitialize="initialize"
        :tilesData="tilesData"
        :notSavedTilesData="notSavedTilesData"
        @isTilesDataSaved="setTilesDataSaved"
      />
      <EditTranslations
        ref="editTranslations"
        v-if="modelFields.baseFields.translatableFields.length > 0 && tabName === 'translations'"
        :entityData="entityData"
        :entityType="'InstantWin'"
        :translatableFields="translatableFields"
        :translationsData="translationsData"
        :notSavedTranslationsData="notSavedTranslationsData"
        @isTranslationsDataSaved="setTranslationsDataSaved"
        @notSavedTranslationsData="setNotSavedTranslationsData"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { cloneDeep, isEqual } from 'lodash';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import { dateFormate } from '@/utils/dateFormate';
import { formConfig, pageConfig } from "@/config";
import { instantWins } from '@/config/descriptions/instantWins';
import fieldHelpers from '@/utils/ZiqniFieldHelper.js';
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import instantWinFields from '@/generated/ziqni/store/modules/instantWins/fields';
import EditTranslations from '@/shared/components/supportModels/translations/EditTranslations';
import ActionsForEdit from '@/shared/components/ActionsForEdit';
import PreviewSpiner from '@/shared/UI/Spiner';
import EditInstantWinTiles from '@/shared/components/supportModels/instantWins/EditInstantWinTiles.vue';
import EditInstantWinSettings from '@/shared/components/supportModels/instantWins/EditInstantWinSettings.vue';
import EditCelebrationMessages from '@/shared/components/supportModels/instantWins/EditCelebrationMessages.vue';

export default {
  name: 'EditInstantWin',
  components: {
    EditCelebrationMessages,
    EditInstantWinSettings,
    EditInstantWinTiles,
    EditTranslations,
    ActionsForEdit,
    PreviewSpiner,
  },
  data() {
   return {
     model: 'instantWin',
     entityId: this.$route.params.id,
     entityName: '',
     descriptions: {
       ...instantWins.edit
     },
     texts: {
       ...instantWinsTexts
     },
     isShowDropdown: false,
     tabName: "settings",
     tabClasses: "btn edit-tab",
     selectLabel: formConfig.selectLabel,
     tagPlaceholder: formConfig.tagPlaceholder,
     showCMetaInputs: false,
     showCAdjustmentFactorsInputs: false,
     formData: null,
     accountType: [],
     options: ["Slots", "Casino", "Hockey"],
     idValidate: null,
     nameValidate: null,
     typeValidate: null,
     page: pageConfig.page,
     formList: [],
     translatableFields: [],
     translationsData: {},
     modelFields : {
       ...instantWinFields,
     },
     schedulingData: null,
     dependantOnData: null,
     rewardModels: ['achievement'],
     ruleModels: ['achievement'],
     settingsCollapsed: true,
     dependantOnCollapsed: false,
     schedulingCollapsed: false,
     isReady: false,
     isOneStepEdit: false,
     isSettingsDataSaved: true,
     isTilesDataSaved: true,
     isTranslationsDataSaved: true,
     isCelebrationMessagesDataSaved: true,
     notSavedText: 'Data not saved!',
     notSavedTranslationsData: {},
     notSavedTilesData: false,
     tilesData: [],
     instantWinType: 0,
     instantWinTilesKey: 0,
     entityData: null
   }
  },
  computed: {
    ...mapGetters('instantWins', ['success', 'message', 'loading', 'instantWins', 'instantWin']),
    ...mapGetters('theme', ['theme']),
    isButtonDisabled() {
      if (this.loading) return true;
      return !!this.message;
    },
    created() {
      return dateFormate(this.formData.created)
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('instantWins', [
      'handleGetInstantWins_item',
      'handleUpdateInstantWins'
    ]),
    ...mapActions('fileRepositories', ['handleGetFileRepositories', 'handleGetFileRepositoriesByQuery']),
    ...mapActions('files', ['handleGetFileObjectsByQuery']),
    ...mapActions('rewards', ['handleGetRewardsByQuery']),
    async initialize() {
      this.handleGetInstantWins_item([this.$route.params.id],1,0)
        .then(async data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name
          });

          this.instantWinType = data[0].instantWinType;
          this.tilesData = data[0].tiles.map(tile => ({ ...tile, autoProbability: false }));

          await Promise.all(this.tilesData.map(async (tile) => {
            const entityId = `${this.$route.params.id}/row/${tile.location.row}/col/${tile.location.col}`;

            const rewards = await this.handleGetRewardsByQuery({
              queryRequest: {
                must: [
                  {
                    queryField: 'entityId',
                    queryValues: [entityId]
                  }
                ],
                skip: 0,
                limit: 20
              }
            });

            if (rewards && rewards.length > 0) {
              tile.reward = rewards[0];
            }
          }));

          this.instantWinTilesKey++;

          this.entityData = data[0];

          delete instantWinFields.baseFields.fields.version;

          this.formList = fieldHelpers.prepareEditFormList(
            instantWinFields,
            this.texts.editPage,
            this.descriptions,
            this.instantWin
          );

          this.formList.forEach((field, index) => {
            if (field.key === 'statusCode') {
              this.formList.splice(index, 1)
            }
          })

          if (Object.keys(data[0]).includes('dependantOn')) {
            if (data[0].dependantOn) {
              this.dependantOnData = cloneDeep(data[0].dependantOn);
            } else {
              this.dependantOnData = {
                dependantOn: {
                  must: [],
                  mustNot: [],
                  should: [],
                  shouldMatchAtLeast: 1,
                },
                entityType: 'InstantWin',
              }
            }
          }
        });

      this.translatableFields = instantWinFields.baseFields.translatableFields;

      this.isOneStepEdit = !this.ruleModels.includes(this.model)
        && !this.rewardModels.includes(this.model)
        && !this.modelFields.baseFields.translatableFields.length;

      this.isReady = true;
    },
    showTab(name) {
      this.tabName = name;
    },
    setTilesDataSaved(val) {
      this.formData.tiles = val;
      this.isTilesDataSaved = true;
    },
    setTranslationsDataSaved(val) {
      this.isTranslationsDataSaved = val;
    },
    setNotSavedTranslationsData(obj) {
      this.notSavedTranslationsData = obj;
    },
    updateFormData(val) {
      if (this.formData && !isEqual(this.formData, val)) {
        this.isSettingsDataSaved = false;
      }
      this.formData = val;
    },
  },
  watch: {
    instantWin() {
      this.entityName = this.instantWin.name
    },
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins.scss";
.update-entity {
  height: 100%;
  .card-header {
    background-color: #f3f3f3;
    & ~ .row:first-of-type {
      margin-top: 1rem;
    }
  }
  .card {
    &.zq--wizard-card {
      background-color: var(--zq-sub-bg);
    }
  }
  .edit-tooltip-wrapper {
    display: inline-block;
    position: relative;
    .tooltip-old {
      position: absolute;
      visibility: hidden;
      top: -4px;
      left: 16px;
    }
  }
  .multiselect {
    &--disabled {
      .multiselect__single {
        background: none;
      }
    }
  }
  .fa-question-circle-o:hover ~ .tooltip-old {
    visibility: visible !important;
  }
  .message {
    color: var(--zq-warn);
  }
  .notSaved {
    color: var(--zq-warn) !important;
  }
  .form-content {
    border-top: 0;
  }
  .edit-page__header {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    background-color: var(--zq-main-bg);
  }
  .wrapper_edit_forms {
    margin-top: 20px;
    display: flex;
  }
  .edit-tab {
    width: 50%;
    height: 35px;
    cursor: pointer !important;
    border-radius: 12px 12px 0 0;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #858585;
    text-align: left;

    &.active {
      border-bottom: solid 2px #007dfb;
    }

    &.btn {
      padding: 0.375rem 1rem;
      text-align: center;
    }
  }
  .tabs_wrapper {
    height: 100%;
    max-height: 40px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    border-radius: 12px 12px 0 0;
  }
  & > .form-content {border-top: none}
  .update-entity-form {
    height: calc(100% - 50px);
    input::placeholder {
      font-style: italic;
    }
    .card {
      background-color: var(--zq-sub-bg);
    }
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      .form-control.is-valid {
        padding-right: 15px;
      }
      .custom-multi-select .multiselect__select {
        opacity: 0;
      }
      .adjustment-factors-data {
        .fa-question-circle-o {
          top: 10px;
          right: -20px;
        }
      }
      .search-icon {
        position: absolute;
        width: 30px !important;
        height: 47px !important;
        right: 15px;
        top: 1px;
        padding: 4px 8px;
        text-align: center;
      }
      .editor {
        min-height: 200px;
        height: 200px;
      }
      .fa-question-circle-o {
        position: absolute;
        top: 0;
        right: -10px;
      }
      .meta-data {
        min-width: 70px;
        .fa-question-circle-o {
          top: 10px;
        }
      }
    }
  }
  @include media-breakpoint-up('768px') {
    .edit-page__header {
      padding: 0;
    }
    .wrapper_edit_forms {
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;
      margin-bottom: -8px;
    }
    .tabs_wrapper {
      margin: 0 3rem;
      min-width: 375px;
      width: 47%;
      max-height: 54px;
    }
    .edit-tab {
      height: 54px;
      line-height: 42px;
      cursor: pointer !important;
      &:hover {
        color: #858585;
      }
    }
  }
}
</style>
