<template>
  <div class="instant-win-settings">
    <!--  Header    -->
    <div class="p-0 m-0 d-flex w-100 align-items-center justify-content-between">
      <CCol col="2" class="d-flex p-0">
        <h3 class="mb-3 mr-1 bread-content">{{ 'Edit Settings' }}</h3>
        <IconWithTooltip class="zq--header-tooltip" :text="descriptions.settings.titleDescription"/>
      </CCol>
      <div class="message" v-if="!isSettingsDataSaved">Data not saved!</div>
      <CButton
        class="action-create-button zq--responsive-button__common"
        type="submit"
        @click="updateSettings"

      >
        Update Settings
      </CButton>
    </div>
      <ZqInstantWinType
        :label="getLabel('instantWinType')"
        v-model="localInstantWinType"
        :tooltip="getTooltipByName('instantWinType')"
        :isDisabled="true"
      />
      <div class="d-flex align-items-center w-100 name-tag-wrapper">
        <CIInputCol
          class="col-5"
          :label="getLabel('id')"
          :tooltip="getTooltipByName('id')"
          v-model="id"
          :placeholder="'id'"
          :required="true"
          :name="'id'"
          :disabled="true"
        />
      </div>
      <div class="d-flex align-items-center w-100 name-tag-wrapper">
        <CIInputCol
          class="col-5"
          :label="getLabel('name')"
          :tooltip="getTooltipByName('name')"
          v-model="name"
          :placeholder="'name'"
          :required="true"
          :name="'name'"
        />
        <CITagField
          class="col-5 pl-0 instant-win-tag"
          :is-column="true"
          v-model="tags"
          :type="model"
          :tooltip="getTooltipByName('tags')"
        />
      </div>
      <div class="d-flex align-items-center w-100 text-area-fields-wrapper">
        <CITextArea
          class="col-5"
          :is-column="true"
          :label="getLabel('description')"
          :tooltip="getTooltipByName('description')"
          v-model="description"
          :placeholder="'description'"
          :name="description"
          :isToolbar="true"
        />
        <CITextArea
          class="col-5"
          :is-column="true"
          :label="getLabel('termsAndConditions')"
          :tooltip="getTooltipByName('termsAndConditions')"
          v-model="termsAndConditions"
          :placeholder="'description'"
          :name="termsAndConditions"
          :isToolbar="true"
        />
      </div>
      <div class="d-flex align-items-center">
        <CIMetaDataField
          v-model="metadata"
          class="w-100"
          :label="getLabel('metadata')"
          :tooltip="getTooltipByName('metadata')"
          :placeholder="'placeholder'"
          :colSm="'5'"
          :colMd="'5'"
          :colLg="'5'"
          :colXl="'5'"
          :paddingRight="'pr-4'"
        />
      </div>
  </div>
</template>

<script>
import CITagField from '@/shared/components/CITagField.vue';
import CITextArea from '@/shared/components/formComponents/CITextArea.vue';
import ZqInstantWinType from '@/shared/components/ZqInstantWinType.vue';
import CIInputCol from '@/shared/components/formComponents/CIInputCol.vue';
import CIMetaDataField from '@/shared/components/CIMetaDataField.vue';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';
import { startCase } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'EditInstantWinSettings',
  components: { IconWithTooltip, CIMetaDataField, CIInputCol, ZqInstantWinType, CITextArea, CITagField },
  props: {
    list: [Array],
    tilesData: [Array],
    descriptions: Object,
    texts: Object,
    instantWinType: [Number, String]
  },
  data() {
    return {
      formList: null,
      obj: null,
      requiredText: 'Required',
      model: 'instantWin',
      id: '',
      localInstantWinType: 1,
      name: '',
      description: '',
      termsAndConditions: '',
      metadata: null,
      customFields: null,
      tags: [],
      isReady: false,
      isSettingsDataSaved: true
    }
  },
  created() {
    this.initialize();
  },
  watch: {
    localInstantWinType(val) {
      const item = this.formList.find(item => item.key === 'instantWinType');
      item.value = val;
    },
    name(val) {
      const item = this.formList.find(item => item.key === 'name');
      item.value = val;
    },
    description(val) {
      const item = this.formList.find(item => item.key === 'description');
      item.value = val;
    },
    termsAndConditions(val) {
      const item = this.formList.find(item => item.key === 'termsAndConditions');
      item.value = val;
    },
    metadata(val) {
      const item = this.formList.find(item => item.key === 'metadata');
      item.value = val;
    },
    tags(val) {
      const item = this.formList.find(item => item.key === 'tags');
      item.value = val;
    },
  },
  methods: {
    ...mapActions('instantWins', ['handleGetInstantWins_item', 'handleUpdateInstantWins']),
    initialize() {
      this.formList = this.list;

      this.id = this.getValueByName('id');
      this.localInstantWinType = this.getValueByName('instantWinType');
      this.name = this.getValueByName('name');
      this.description = this.getValueByName('description');
      this.termsAndConditions = this.getValueByName('termsAndConditions');
      this.metadata = this.getValueByName('metadata');
      this.tags = this.getValueByName('tags');
      this.customFields = this.getValueByName('customFields');
      this.isReady = true;
    },
    getLabel(fieldName) {
      const foundObject = this.list.find(item => item.key === fieldName);

      return foundObject?.required ? startCase(foundObject.label) + '*' : startCase(foundObject.label);
    },
    getValueByName(name) {
      return this.formList.find(item => item.key === name)?.value;
    },
    getTooltipByName(name) {
      return this.descriptions.settings[name]
    },
    updateSettings(isOneStepEdit = false) {
      const data = {
        id: this.id,
        constraints: this.getValueByName('constraints'),
        name: this.name,
        description: this.description,
        instantWinType: this.localInstantWinType,
        termsAndConditions: this.termsAndConditions,
        tiles: [...this.tilesData],
        metadata: this.metadata,
        customFields: this.customFields
      }

      data.tiles.forEach(tile => {
        if (tile.reward) {
          delete tile.reward.rewardType;
          delete tile.reward.id;
          delete tile.reward.spaceName;
          delete tile.reward.created;
          delete tile.reward.entityType;
          delete tile.reward.entityId;
          delete tile.reward.translatableFields;
        }
        delete tile.autoProbability;
      });

      const body = [data];

      this.handleUpdateInstantWins({updateInstantWinRequestArray: body})
        .then(data => {
          this.isSettingsDataSaved = true;
          if (data.length && isOneStepEdit) {
            // setTimeout(() => {
            //   this.$router.push({
            //     name: 'PreviewInstantWin',
            //     params: {
            //       id: data[0].id,
            //     }
            //   })
            // }, 1500)
          }
        })
    }
  }
};
</script>

<style scoped lang="scss">

</style>
